import "./styles.css";
import PartySocket from "partysocket";
declare const PARTYKIT_HOST: string;

window.givePoint = () => {
  console.log("giving point");
}

class Client {
  private output: HTMLDivElement;
  private conn: PartySocket;
  private pingInterval: ReturnType<typeof setInterval> | null = null;

  constructor() {
    this.output = document.getElementById("app") as HTMLDivElement;
    this.conn = this.initializeConnection();
    this.attachEventListeners();
  }

  private initializeConnection() {
    return new PartySocket({
      host: PARTYKIT_HOST,
      room: "game-room",
    });
  }

  private add(text: string) {
    this.output.appendChild(document.createTextNode(text));
    this.output.appendChild(document.createElement("br"));
  }

  private attachEventListeners() {
    this.conn.addEventListener("message", (event) => this.handleMessage(event));
    this.conn.addEventListener("open", () => this.handleOpen());
    document.getElementById("generate")!.addEventListener("click", () => this.requestProgram());
  }

  private handleMessage(event: MessageEvent) {
    const parsedEvent = JSON.parse(event.data);
    if (parsedEvent.message) {
      this.add(parsedEvent.message);
      return;
    }
    if (parsedEvent.error) {
      this.add(parsedEvent.error);
      return;
    }
    const aiCode = parsedEvent.aiResponse;
    const code = aiCode.match(/```(.*?)```/s);
    let p5Code = "";
    if (code) {
      p5Code = code[1]
      .replace(/^js/, '')
      .replace(/^javascript/, '')
    }

    this.add("😲 Wow, someone bought some code! 🍲👩‍🍳");


    var script = document.createElement("script");
    script.innerHTML = p5Code;
    document.head.appendChild(script);
  }

  private handleOpen() {
    this.add("🫡 Connected!");
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
  }

  private requestProgram() {
    const claudeApiKey = this.getClaudeKey();
    if (claudeApiKey) {
      this.conn.send(JSON.stringify({
        key: claudeApiKey,
      }));
    } else {
      document.getElementById("claude-api-key-error")!.innerText = "Please enter an API key to generate.";
      document.getElementById("claude-api-key")!.focus();
    }
  }

  private getClaudeKey() {
    const claudeApiKeyElt = document.getElementById("claude-api-key") as HTMLInputElement
    return claudeApiKeyElt.value;
  }
}

new Client();
